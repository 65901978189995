
import React from "react";
import { Link } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import MainLayout from "../../layouts/MainLayout";
import "./terms.scss";

const Terms = () => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <>
        <div className="terms">
          <div>
            <h1 className="box-three">{t("terms:termsTitle")}</h1>

            <p className="box-two">
              <Trans i18nKey='terms:termsOne'>
                part1
                <Link to="/" className="decoration"></Link>
                part2
              </Trans>
            </p>
            <p className="space">
            <Trans i18nKey='terms:termsTwo'>
              part1<i></i>
            </Trans>
            </p>
            <p className="space">
            <Trans i18nKey='terms:termsThree'>
              part1<i></i>
            </Trans>
            </p>
            <p className="space">
            <Trans i18nKey='terms:termsFour'>
              part1<i></i>
            </Trans>
            </p>
            <p className="space">
            <Trans i18nKey='terms:termsFive'>
              part1<i></i>
            </Trans>
            </p>
            <p className="space">
            <Trans i18nKey='terms:termsSix'>
              part1<i></i>
            </Trans>
            </p>
            <p className="space">
            <Trans i18nKey='terms:termsSeven'>
              part1<i></i>
            </Trans>
            </p>
            <p className="space box-two">
            <Trans i18nKey='terms:termsEight'>
              part1<i></i><a href="mailto:info@gorda.rs" />
            </Trans>
            </p>

            <p>
              <Trans i18nKey='terms:termsNine'>
                part1
                <Link to="/" className="decoration"></Link>
                part2
              </Trans>
            </p>
            <p>{t("terms:termsTen")}</p>
            <p className="box-two">
              <Trans i18nKey='terms:termsEleven'>
                part1
                <a href="mailto:info@gorda.rs" />
                part2
              </Trans>
            </p>

            <p>{t("terms:termsTwelve")}</p>
            <p>
            <Trans i18nKey='terms:termsThirteen'>
                part1
                <Link to={t("routes:cookie")}></Link>
                part2
              </Trans>
            </p>
            <p className="box-two">{t("terms:termsFourteen")}</p>

            <p className="box-two">{t("terms:termsFifteen")}</p>

            <p className="box-two">{t("terms:termsSixteen")}</p>

            <p className="box-two">{t("terms:termsSeventeen")}</p>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default Terms;
